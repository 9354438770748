// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-canaldenuncias-js": () => import("./../../../src/pages/canaldenuncias.js" /* webpackChunkName: "component---src-pages-canaldenuncias-js" */),
  "component---src-pages-cases-mary-kay-jsx": () => import("./../../../src/pages/cases/mary-kay.jsx" /* webpackChunkName: "component---src-pages-cases-mary-kay-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-streaming-js": () => import("./../../../src/pages/live-streaming.js" /* webpackChunkName: "component---src-pages-live-streaming-js" */)
}

